import { Component, OnInit, ViewChild, ElementRef, Output, Inject } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { MatPaginator, MatSort } from '@angular/material';
import { ProcessDataSource } from '../../services/process.datasource';
import { ProcessService } from '../../services/process.service';
import { fromEvent, merge, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { CompanyRepresentativeService } from 'src/app/services/companyRepresentative.service';
import { CompanyRepresentativesStatusName } from 'src/app/entities/util';
import { Company } from 'src/app/entities/company';
import { JwtHelperService } from '@auth0/angular-jwt';
import { YearService } from 'src/app/services/year.service';
import { FormControl } from '@angular/forms';
import { FileUploader } from "ng2-file-upload";
import { SharedService } from 'src/app/services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/services/user.service';
import { ReportService } from 'src/app/services/report.service';
export interface DialogData {
  name: string;
}
@Component({
  selector: 'tas-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public uploader: FileUploader;
  company: any;
  public searchCompanyCont = new FormControl();
  displayedColumns: string[] = ['link', 'name', 'status', 'due_date', 'started_on', 'last_modified', 'rec_num'];
  displayedColumnsSmall: string[] = ['link', 'name', 'status'];
  currentYear = (new Date().getFullYear()).toString()
  currentYearInt = new Date().getFullYear()
  displayedYears: string[] = [];
  isCurrentYearActive = true;
  dataSource: ProcessDataSource;
  allowedMimeType = ['.xls', '.xlsx', '.pdf', '.doc', '.docx'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input', { static: false }) input: ElementRef;
  fileName: string;
  name: string;
  companies: Company[];
  statusNames = CompanyRepresentativesStatusName
  filteredOptions: Observable<Company[]>;
  @ViewChild('fileUpload', { static: false }) selectedFile: any;
  subscriptionWorkspace: any
  displayFn(company?: Company): string | undefined {
    if (company) {
      this.company = {}
      this.company = company
    }
    return company ? (company.name + " (" + (company.tin ? company.tin : "TIN N/A") + ")") : undefined;
  }
  hasWorkspaces = false;
  constructor(private processService: ProcessService, private router: Router, private route: ActivatedRoute,
    public dialog: MatDialog, private companyRepresentativeService: CompanyRepresentativeService,
    public yearService: YearService, private sharedService: SharedService,
    private spinner: NgxSpinnerService,
    private userService: UserService, public jwtHelper: JwtHelperService) {
  
    this.subscriptionWorkspace = sharedService.getWorkspace$().subscribe(workspace => { 
      if (workspace) { 
        this.hasWorkspaces = true;
        this.dataSource = new ProcessDataSource(this.processService)
        this.dataSource.loadProcessess(this.currentYear, '', 'asc', 'name', 0, 10);
      } 
    }) 
    this.userService.getUserProfile().subscribe((val) => {
      this.sharedService.setUserProfile(val)
    })
  }
 
  ngOnInit() {
      this.yearService.getList("year").subscribe(val => {
        val.forEach((element, index) => {
          if (index===0){
            this.currentYear = element.year
          }
          if (element.visible)
            this.displayedYears.push(element.year)
          if (element.year === this.currentYear) {
            this.isCurrentYearActive = element.isActive
          }
        });
        if (this.route.snapshot.paramMap.get('year')) {
          this.currentYear = this.route.snapshot.paramMap.get('year')
        }
        if (localStorage.getItem("default_workspace") && this.hasWorkspaces===false){
          this.hasWorkspaces = true;
          this.dataSource = new ProcessDataSource(this.processService)
          this.dataSource.loadProcessess(this.currentYear, '', 'asc', 'name', 0, 10);
        }
      })
  }

  handleSelection(event) {
    this.currentYear = event.value;
    this.yearService.getList("year").subscribe(val => {
      this.displayedYears = []
      val.forEach(element => {
        if (element.visible)
          this.displayedYears.push(element.year)
        if (element.year === this.currentYear) {
          this.isCurrentYearActive = element.isActive
        }

      });
    })
    this.dataSource.loadProcessess(this.currentYear, '', 'asc', 'name', 0, this.paginator.pageSize);
  }

  ngAfterViewInit() {
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          tap(() => {
            this.paginator.pageIndex = 0;
            this.loadProcessPage();
          })
        )
        .subscribe();
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          tap(() => this.loadProcessPage())
        )
        .subscribe();
  }
  getUserFromToken() {
    if (this.jwtHelper.decodeToken(localStorage.getItem('access_token')))
      return this.jwtHelper.decodeToken(localStorage.getItem('access_token')).username;
    return null;
  }

  loadProcessPage() {
    this.dataSource.loadProcessess(
      this.currentYear,
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize); 
  }

  openDialog(): void {
    let dialogData =  {
      width: '600px',
      maxHeight: 'calc(100vh - 90px)',
      height: 'auto',
      data: { year: this.currentYear, spinner: this.spinner }
    }
    localStorage.getItem('default_workspace') && (dialogData.data['workspace']=localStorage.getItem('default_workspace'))
    const dialogRef = this.dialog.open(NewProcessDialog,dialogData);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openUploadDialog(type): void {
    var data = {}
    data = { uploader: this.uploader, selectedFile: this.selectedFile }
    const dialogRef = this.dialog.open(UploadFileDialog, {
      width: '400px',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
}


@Component({
  selector: 'new-process-dialog',
  templateUrl: 'new-process-dialog.html',
  styleUrls: ['./new-process-dialog.css']
})
export class NewProcessDialog implements OnInit {
  year: string;
  spinner: NgxSpinnerService;
  formType = null;
  processes = [];

  selectedMonth = 0
  months = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  selectedQuarter = 0
  quarters = [1,2,3,4]
  constructor(
    public dialogRef: MatDialogRef<NewProcessDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public router: Router,
    private processService: ProcessService, private reportService: ReportService) {
    this.year = data['year'];
    this.spinner = data['spinner']
  }
  setFormType(tp){
    this.formType = tp
    this.reportService.getReportsListByType(this.year, this.formType).subscribe(
      data => {
        this.processes = data.records
      });
  }
  backToFormTypeSelection(){
    this.formType = null
    this.selectedMonth = 0
    this.selectedQuarter = 0
  }
  ngOnInit() {
     
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  timeMilis: number;
  navigateToProcess(report) {
    this.spinner.show()
    var locTm = new Date().getTime();
    if (this.timeMilis === undefined) this.timeMilis = locTm
    if (locTm - this.timeMilis > 2000 || this.timeMilis === locTm) {
      var completeProcess = {
        'key': '',
        'name': '',
        'year': '',
        'stages': [],
        'tin' : localStorage.getItem('default_workspace')
      };
      this.selectedMonth && (completeProcess['month'] = this.selectedMonth)
      this.selectedQuarter && (completeProcess['quarter'] = this.selectedQuarter)
      completeProcess.key = report.key
      completeProcess.name = report.name
      completeProcess.year = this.year
      completeProcess['type'] = report.type
      completeProcess['status'] = 'In Progress (Draft)';
      completeProcess['progress'] = Math.floor(Math.random() * 99) + 1;
      completeProcess.stages = [];
      this.processService.addProcess(completeProcess).subscribe
        (data => {
          this.spinner.hide()
          this.router.navigate(['process/' + data.id]);
        });
    }
  }
}


@Component({
  selector: 'upload-file-confirmation-dialog',
  templateUrl: 'upload-file-dialog.html',
})
export class UploadFileDialog implements OnInit {
  uploader
  selectedFile: any;
  constructor(
    public dialogRef: MatDialogRef<UploadFileDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private spinner: NgxSpinnerService) {
    this.uploader = data['uploader'];
    this.selectedFile = data['selectedFile']
  }
  processingStages = []
  ngOnInit() {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  uploadExcel() {
    this.spinner.show();
    this.dialogRef.close();
    this.uploader.uploadAll();
    this.clearSelectedFile();
  }
  clearSelectedFile() {
    this.selectedFile.nativeElement.value = '';
  }
}
